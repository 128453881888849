import React from 'react';
import { Container } from '@material-ui/core';
// import Header from '../components/navigation/Header';

const AuthLayout = ({ children }) => {
  return <>
    <Container maxWidth="sm">{children}</Container>
  </>;
};

export default AuthLayout;