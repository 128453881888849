import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Gvar from '../utils/Gvar';
import Logger from '../utils/Logger';
import Utils, {loadProfile} from '../utils/Utils';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MenuItem, Snackbar } from '@material-ui/core';

export function TopBar(props) {
    var context = props.context
    return (
        <>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography component={'span'} variant="h6" >
                {context.state.title}
            </Typography>
            </Toolbar>
        </AppBar>
        </>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class TokenListrik extends Component {
    state = {
        listProduk : [],
        reditectToTopup : false,
        idPelanggan:'',
        showConfirmDialog:false,
        h_jual:'',
        showSnackBar:false,
        textSnackBar:"",
        title:"Token Listrik",
        showLoading:false,
        showTokenInfo:false,
        tokenInfo:'',
        trxPesan:'',
        kodeProduk:'',
        showConfirmPIN:false
    }
    constructor(props){
        super(props);
        this.data_member = JSON.parse(localStorage.getItem('data_member'));
        this.dataConfirm = {
            idPelanggan:'',
            nama_produk : '',
            keterangan_produk : '',
            h_jual :'0'
        }
    }

    componentDidMount(){
       this.getDataProduk();
       loadProfile();
       setTimeout(() => {
        this.idPelRef.focus();
       }, 500);
    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    handleChange = (e) => {
        if(e.target.value !== ""){
            var index = this.state.listProduk.map(i=>i.kode_produk).indexOf(e.target.value);
            this.dataConfirm.nama_produk = this.state.listProduk[index].nama_produk;
            this.dataConfirm.keterangan_produk = this.state.listProduk[index].keterangan_produk;
            this.dataConfirm.h_jual = this.state.listProduk[index].h_jual;
            this.sisaSaldo = Gvar.saldo-this.dataConfirm.h_jual;
        }
        this.setState({kodeProduk:e.target.value})
    };

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    getDataProduk(){
        var bodyJson = {jenis:'TOKEN_LISTRIK',group : 'PLN Prabayar',id_member:this.data_member.id,token:Utils.getMyToken()}
        
        fetch(Gvar.server+'index.php/produk/nonprefix', {
        method: 'POST',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
        ).then(res => {
            this.setState({listProduk:res.data})   
        }
        ).catch(err => {
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            this.setState({showLoading:false})
        })
    }

    sendTransaction (){
        var noPel = this.state.idPelanggan;
        var kodeProduk = this.state.kodeProduk;
        var pinTransaksi = this.state.pinTransaksi;
        
        if(pinTransaksi === ""){
            this.setState({ showSnackBar: true, textSnackBar : "pin transaksi belum diisi"}); return;
        }
        this.setState({showLoading:true,showTokenInfo:false,tokenInfo:''})

        var bodyJson = { 
            id_member: this.data_member.id,
            no_tujuan: noPel.replace(/ - /g,''),
            kode_produk:kodeProduk,
            pin_transaksi:Utils.getHash(pinTransaksi),
            trx_source : 'WEB_APP',
            token : Utils.getMyToken(),
            metode_pembayaran : 'EWALLET',
            channel_code : 'SALDO',
            inv_info_pembayaran : { 
                nama_pengirim : Gvar.appName,
                alamat_pengirim : "Bekasi",
                email_pengirim : Gvar.senderMail,
                link_belanja_pengirim: "",
                no_wa_pengirim : Gvar.whatsappNumber,
                hotline_pengirim : "",
                web_pengirim : Gvar.webSender,
                nama_penerima : this.data_member.nama,
                alamat_penerima : this.data_member.alamat,
                email_penerima : this.data_member.email,
                no_hp_penerima : this.noTujuan,
                nama_ewallet : "Saldo"
            }
        }

        Gvar.paramForTopUp = bodyJson;

        Logger.d(bodyJson);

        fetch(Gvar.server+'index.php/transaksi', {
        method: 'POST',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //console.log(res)
                // this.setState({listProduk:res})
                this.showToast(res.data.status+", "+res.data.pesan,false);
                if(res.data.status === "SUKSES"){
                    this.setState({showLoading:false,showTokenInfo:true,trxPesan:res.data.pesan,tokenInfo:res.data.sn,idPelanggan:'', kodeProduk:''});
                    loadProfile();
                }else if(res.data.status === "PENDING"){
                    this.setState({showTokenInfo:false,trxPesan:res.data.pesan,tokenInfo:res.data.sn});
                    Gvar.checkStatusService = setInterval(() => {
                        this.checkStatus(res.data.id_request);
                    }, 2000);
                }else{
                    this.showToast(res.data.pesan);
                    this.setState({showLoading:false})
                }
            }
            ).catch(err => {
                this.showToast("Koneksi Gagal");
                this.setState({showLoading:false})
             })
    }

    checkStatus(idRequest){
        this.setState({showLoading:true})
        var bodyJson = { 
            id_member: this.data_member.id,
            token : Utils.getMyToken()
        }
        fetch(Gvar.server+'index.php/transaksi/cek/'+idRequest, {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => { 
                Gvar.homeContext.pullMessage();    
                try {
                    if(res.data.status === "SUKSES" || res.data.status === "GAGAL"){
                        loadProfile();
                        clearInterval(Gvar.checkStatusService);
                        this.setState({ showLoading:false})
                        if(res.data.status === "SUKSES"){
                            this.showToast(res.data.pesan);
                            this.setState({showTokenInfo:true,trxPesan:res.data.pesan,tokenInfo:res.data.sn,idPelanggan:'', kodeProduk:''});
                        }else{
                            this.showToast(res.data.pesan);
                        }
                    }
                } catch (error) {
                    Logger.e(error.toString())
                }
            }
        ).catch(err => {
            clearInterval(Gvar.checkStatusService);
            this.showToast("Koneksi Gagal");
            this.setState({showLoading:false})
        })
    }

    onChangeNoPel = (e) => {
        if(e.target.value.length > 12){
            return;
        }
        this.setState({idPelanggan:e.target.value});
    }

    showToast(text,autoClose=true){
        this.setState({ showSnackBar: true, textSnackBar :text});
        if(autoClose){
            setTimeout(() => {
                this.setState({ showSnackBar: false});
            }, 4000);
        }
    }

    copySerialNumb() {
        var range = document.createRange();
        range.selectNode(document.getElementById('serialNumb'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
      
        this.showToast("Teks berhasil disalin");
    }

    render() {
        const polygon = (
            <>
                <Dialog
                    open={this.state.showConfirmDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Konfirmasi"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText component={"div"} id="alert-dialog-slide-description">
                        <table style={{fontSize:13}}>
                            <tbody>
                                <tr>
                                    <td>ID Pelanggan</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right",fontWeight:'bold'}}></td>
                                </tr>
                                <tr>
                                    <td colSpan="3" style={{textAlign:"right",fontWeight:'bold'}}>{Utils.numberFormatIDPLN(this.dataConfirm.idPelanggan)}</td>
                                </tr>
                                <tr>
                                    <td>Keterangan Produk</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right",fontWeight:'bold'}}>{this.dataConfirm.keterangan_produk}</td>
                                </tr>
                                <tr>
                                    <td>Harga</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right",fontWeight:'bold'}}>{Utils.formatRupiah(this.dataConfirm.h_jual,'.')}</td>
                                </tr>
                                <tr>
                                    <td>Saldo</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right",fontWeight:'bold'}}>{Utils.formatRupiah(Gvar.saldo,'.')}</td>
                                </tr>
                                <tr>
                                    <td>Sisa Saldo</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right",fontWeight:'bold'}}>{Utils.formatRupiah(this.sisaSaldo,'.')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{
                        this.setState({ showConfirmDialog: false });
                    }} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        loadProfile();
                        this.setState({showConfirmPIN:true,showConfirmDialog:false})
                        setTimeout(() => {
                            this.pinTransaksiRef.focus();
                        }, 500);
                    }} color="primary">
                        Lanjut
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showConfirmPIN}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseConfirm}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"PIN Transaksi"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"} id="alert-dialog-slide-description">
                            <TextField
                                    id="inputPINTransaksi"
                                    label="PIN Transaksi"
                                    type="password"
                                    name="password"
                                    autoComplete="none"
                                    margin="normal"
                                    variant="outlined"
                                    autoFocus
                                    value={this.state.pinTransaksi}
                                    onChange={e=>this.setState({pinTransaksi:e.target.value})}
                                    inputRef={(ref)=>this.pinTransaksiRef=ref}
                                />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:false});
                    }} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:false});
                        this.sendTransaction();
                    }} color="primary">
                        Beli
                    </Button>
                    </DialogActions>
                </Dialog>
                <Card style={{marginTop : 20}}>
                    
                    <CardContent>
                    <Grid
                        item xs={12}
                        container
                        direction="column"
                        >
                            <TextField
                                id="inputNoPel"
                                label="ID Pelanggan"
                                type="number"
                                name="nomorhp"
                                autoComplete="none"
                                margin="normal"
                                variant="outlined"
                                value={this.state.idPelanggan}
                                onChange={this.onChangeNoPel}
                                inputRef={ref=>this.idPelRef=ref}
                            />

                            <FormControl variant="outlined">
                                <InputLabel id="pilih-nominal">Pilih Nominal</InputLabel>
                                <Select
                                    labelId="pilih-nominal"
                                    id="pilih-nom"
                                    value={this.state.kodeProduk}
                                    onChange={this.handleChange}
                                    inputRef={ref=>this.kodeProdukRef=ref}
                                    label="Pilih Nominal">
                                    <MenuItem value="">
                                        <em>--Pilih--</em>
                                    </MenuItem>
                                    {this.state.listProduk.map(data => (
                                        <MenuItem  key={data.id} value={data.kode_produk}>{data.nama_produk}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button variant="contained" type="submit" onClick={()=>{
                                this.dataConfirm.idPelanggan = this.state.idPelanggan;
                                this.setState({showTokenInfo:false});
                                if(this.state.idPelanggan === ""){
                                    this.idPelRef.focus();
                                    this.showToast("ID pelanggan belum diisi.");
                                    return;
                                }else if(this.state.idPelanggan.replace(/ - /g,'').length < 11){
                                    this.idPelRef.focus();
                                    this.showToast("ID Pelanggan harus 11 atau 12 digit angka");
                                    return;
                                }else if(this.state.kodeProduk === ""){
                                    this.kodeProdukRef.focus();
                                    this.showToast("Nominal belum dipilih.");
                                    return;
                                }
                                this.setState({showConfirmDialog: true});
                            }}  size="large" color="primary" style={{marginTop: 16, marginBottom: 16}}>
                                Lanjut
                            </Button>
                            <div style={{borderWidth:1,borderColor:'black',borderStyle:'dashed',padding:10,fontSize:11}}>INFO :<br></br> Pembelian token listrik tidak dapat dilakukan di jam <strong>23:00 s/d 00:30 WIB setiap hari</strong>, ini sesuai dengan ketentuan dari PLN</div>
                        </Grid>
                    </CardContent>
                </Card>  
                {this.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null} 
                {this.state.showTokenInfo?(
                    <>
                        <Card style={{marginTop : 20}}>
                            <CardContent>
                            <Grid
                                item xs={12}
                                container
                                direction="column"
                                >
                                    {this.state.trxPesan}<br></br>Ini Tokennya :<br></br>
                                    <span id="serialNumb" style={{fontWeight:'bold',fontFamily:'monospace',fontSize:17}}>
                                    {this.state.tokenInfo.replace(/\//g,'  ')}
                                    </span>
                                    <a style={{textAlign:'right',textDecoration:'none'}} onClick={()=>{
                                        this.copySerialNumb();
                                    }} href="#">salin</a>
                                </Grid>
                            </CardContent>
                        </Card>
                    </>
                ):null}
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                    open={this.state.showSnackBar}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.textSnackBar}</span>}
                />   
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{paddingTop:56}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}

export default TokenListrik;
