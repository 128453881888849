import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from '../utils/Gvar';
import Slide from '@material-ui/core/Slide';
import Utils, {loadProfile} from '../utils/Utils';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logger from '../utils/Logger';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Box, Divider, InputAdornment, List, ListItem, ListItemText } from '@material-ui/core';
import { Check } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
        </AppBar>
        </div>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class HargaJual extends Component {
    state = {
        jenisKartu : '',
        showSnackBar : false,
        textSnackBar : '',
        listProduk : [],
        showConfirmDialog : false,
        showConfirmPIN : false,
        title:"Harga Jual",
        showLoading : false,
        list_jenis : [],
        list_group : [],
        jenis : '',
        group : ''
    }
    constructor(props){
        super(props);
        this.jenisKartu = "";
        this.jenis = "";
        this.dataConfirm = {
            nama_produk : ''
        }
         
        this.refPIN = React.createRef();
    }

    componentDidMount(){
        loadProfile();
        this.getkategori();
        this.getProdukGroup();
    }

    handleClickOpenConfirm(rowData){
        Logger.d("rowData",rowData);
        loadProfile();
        this.dataConfirm = rowData;
        this.sisaSaldo = parseInt(Gvar.saldo)-parseInt(rowData.h_jual)
        this.setState({showConfirmDialog:true});
    };
    
    handleCloseConfirm = () => {
        this.setState({showConfirmDialog:false});
    };

    handleChange = event => {
        // this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    getDataProduk(){
        this.setState({showLoading:true})
        var data_member = JSON.parse(localStorage.getItem('data_member'));
        
        var bodyJson = {jenis:this.state.jenis, group: this.state.group, id_member:data_member.id, token:Utils.getMyToken()}
        
        fetch(Gvar.server+'index.php/produk/all', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        })
        .then(res=>res.json())
        .then(res => {
            //Logger.d(res)
            this.setState({listProduk: res.data, showLoading: false});
            for(let i in res.data){
                document.getElementById("harga-"+i).value = res.data[i].h_jual_member || res.data[i].h_jual;
            }
        }).catch(err => {
            Logger.e(err.toString())
            this.setState({showLoading:false});
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
        })
    }

    formatRupiah(angka, prefix){
        if(angka!==undefined && angka!== null && angka !== ''){
            angka = angka.toString();
            var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split   		= number_string.split(','),
            sisa     		= split[0].length % 3,
            rupiah     		= split[0].substr(0, sisa),
            ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

            // tambahkan titik jika yang di input sudah menjadi angka ribuan
            if(ribuan){
                var separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }

            rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
            return prefix === undefined ? rupiah : (rupiah ? 'Rp ' + rupiah : '');
        }else{
            return '';
        }
    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    getkategori(){
        var bodyJson = {id_member:Utils.getDataMember().id,token:Utils.getMyToken()};
        fetch(Gvar.server+'index.php/produk/kategori-utama', {
                method: 'post',
                body: JSON.stringify(bodyJson),
            }).then(res=>res.json()
        ).then(res => {
            // Logger.d(res)
            var baseCategory = res.data;
            this.setState({list_jenis:baseCategory}); 
            // this.setState({listProduk:res})
        }).catch(err => {
            // this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            this.setState({showLoading:false})
        })
    }

    getProdukGroup(){
        this.setState({ showSnackBar: false, textSnackBar :""});
        var jenis = this.state.jenis;
        var bodyJson = { 
            jenis: jenis,
            id_member:Utils.getDataMember().id,
            'token':Utils.getMyToken()
        }
        fetch(Gvar.server+'index.php/produk/group', {
            method: 'POST',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
        ).then(res => {
            // Logger.d(res)
            this.setState({list_group:res.data}); 
        }).catch(err => {
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            this.setState({showLoading:false})
        })
    }

    handleChangeSelect = async (event) =>{
        await this.setState({jenis:event.target.value,group:'',list_group:[]});
        await this.getProdukGroup();
        await this.getDataProduk();
        
    }
    
    handleChangeSelectGroup = async (event) =>{
        await this.setState({group:event.target.value});
        await this.getDataProduk();
    }

    updateHarga(harga, kodeProduk){
        this.setState({showLoading: false})
        var bodyJson = { 
            harga_jual: harga,
            kode_produk: kodeProduk,
            id_member:Utils.getDataMember().id,
            token:Utils.getMyToken()
        }
        fetch(Gvar.server+'index.php/pengaturan/update_harga_jual', {
            method: 'POST',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => {
            this.setState({showSnackBar: true, textSnackBar: res.message});
            this.setState({showLoading: false});
        }).catch(err => {
            this.setState({showSnackBar: true, textSnackBar: "Koneksi Gagal"});
            this.setState({showLoading: false})
        })
    }
    
    render() {
        // const classes = useStyles();
        const polygon = (
            <>
                {this.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null}
                <FormControl style={{
                    minWidth: 115,
                    marginRight: 16
                }} margin='normal'>
                    <InputLabel id="pilih-jenis">Jenis</InputLabel>
                    <Select
                        labelId="pilih-jenis"
                        id="pilih-jenis-select"
                        value={this.state.jenis}
                        onChange={this.handleChangeSelect}
                    >
                        {this.state.list_jenis.map((data,index)=>{
                            return (
                                <MenuItem value={data.jenis}>{data.jenis.replace(/_/g,' ')}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{
                    minWidth: 175,
                }} margin="normal">
                    <InputLabel id="pilih-group">Group Produk</InputLabel>
                    <Select
                        labelId="pilih-group"
                        id="pilih-group-select"
                        value={this.state.group}
                        onChange={this.handleChangeSelectGroup}
                    >
                        {this.state.list_group.map((data,index)=>{
                            return (
                                <MenuItem value={data.group_produk}>{data.group_produk.replace(/_/g,'')}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <List>
                    {this.state.listProduk.map((data,index) => (
                    <>
                        <ListItem key={index}>
                            <ListItemText primary={data.nama_produk} secondary={
                                <React.Fragment>
                                    <Box marginBottom={2}>
                                        {data.keterangan_produk}
                                    </Box>
                                    <Box component={"div"}>
                                        <TextField variant='filled' style={{marginRight:16}} label="Harga Modal" value={this.formatRupiah(data.h_jual,'Rp ')} disabled/>
                                        <TextField variant='filled' id={"harga-"+index} label={"Harga Jual"} type='number' onChange={(e)=>{
                                           this.state.listProduk[index].h_jual_member = e.target.value;
                                        }} 
                                        onSubmit={()=>this.updateHarga(this.state.listProduk[index].h_jual_member, data.kode_produk)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">
                                            <IconButton
                                              onClick={()=>{
                                                  this.updateHarga(this.state.listProduk[index].h_jual_member || this.state.listProduk[index].h_jual, data.kode_produk)
                                              }}
                                              edge="end"
                                            >
                                              <Check />
                                            </IconButton>
                                          </InputAdornment>,
                                        }}/>
                                    </Box>
                                </React.Fragment>
                                }/>
                            </ListItem>
                        <Divider/>
                    </>
                    ))}
                </List>
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{marginTop:75}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}


export default HargaJual;
