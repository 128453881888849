import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Gvar from '../../utils/Gvar';
// import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Utils from '../../utils/Utils';
import Logger from '../../utils/Logger';
import SendIcon from '@material-ui/icons/Send';
import CaptchaTextGenerator from 'react-captcha-generator';
import Header from '../../components/navigation/Header';

class ForgotPassword extends Component {

    constructor(props){
        super(props);
        this.state = ({
            list_group :[],
            title : 'Lupa Password',
            showSnackBar : false,
            showLoading : false,
            showScreenSMSCode : false,
            showScreenNewPassword : false,
            showForgotScreen:true,
            no_hp : '',
            kodeOtp:'',
            newPassword : ''
        });
        this.captchaResult = this.captchaResult.bind(this);
    }

    componentDidMount(){
        // this.props.appContext.setState({showLoading:false})
        // this.getProdukGroup();
    }
    
    onClickItem(group,jenis){
        
    }

    onLupaPassword(){
        
        this.setState({showSnackBar: false});
        var no_hp = document.getElementById("inputNoHp");
        this.state.no_hp = no_hp.value;
        if(no_hp.value === ""){
            no_hp.focus();
            this.setState({showSnackBar: true, textSnackBar :"No hp tidak boleh kosong"});
            return;
        }
        var kodeCaptcha = document.getElementById('inputCaptcha');
        if(kodeCaptcha.value == ''){
            kodeCaptcha.focus();
            this.setState({showSnackBar: true, textSnackBar :"Kode Captcha Tidak Boleh Kosong"});
            return;
        }
        if(this.state.captchaCode!==kodeCaptcha.value){
            this.setState({ showSnackBar: true, textSnackBar :"Kode Captcha salah"});
            setTimeout(() => {
                window.location.reload();
            }, 2000);
            return;
        }
        
        this.setState({showLoading:true})
        
        setTimeout(() => {
            var hash = Utils.getHash(no_hp.value+Gvar.secretForgotPassword);
            fetch(Gvar.server+'index.php/member/forgotpassword?no_hp='+no_hp.value+'&key='+hash, {
            method: 'get'
            }).then(res=>res.json()
                ).then(res => {
                    this.setState({showLoading:false});
                    // Logger.log(res)
                    if(res.status === 'ok'){
                        this.setState({ showSnackBar: true, textSnackBar :res.keterangan,showScreenSMSCode:true,showForgotScreen:false});
                    }else{
                        this.setState({ showSnackBar: true, textSnackBar :res.keterangan});
                    }
                }
                ).catch(err => {
                    Logger.d(err.toString());
                    this.setState({showLoading:false});
                    this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
                })
        }, 2000);
    }

    konfirmasiKodeOTP(){
        this.setState({showSnackBar: false});
        var sms_code = document.getElementById("inputSmsCode");
        var no_hp = this.state.no_hp;
        if(sms_code.value === ""){
            sms_code.focus();
            this.setState({showSnackBar: true, textSnackBar :"Kode OTP tidak boleh kosong"});
            return;
        }

        this.setState({showLoading:true})
        
        var sms_code_hash = Utils.getHash(sms_code.value);
        var hash = Utils.getHash(no_hp+sms_code_hash+Gvar.secretForgotPassword);
        fetch(Gvar.server+'index.php/member/check_kode_otp?no_hp='+no_hp+'&sms_code='+sms_code_hash+'&key='+hash, {
            method: 'get'
        })
        .then(res=>res.json())
        .then(res => {
            this.setState({showLoading:false});
            // Logger.log(res)
            if(res.status === 'ok'){
                this.setState({showScreenSMSCode:false,showScreenNewPassword:true,newPassword:res.new_password});
                setTimeout(() => {
                    alert(res.keterangan);
                }, 1000);
            }else{
                this.setState({ showSnackBar: true, textSnackBar :res.keterangan});
            }
        }).catch(err => {
            Logger.d(err.toString());
            this.setState({showLoading:false});
            this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
        })
    }
    
    handleCloseSnackbar = () => {
        this.setState({showSnackBar:false});
    }

    copy(){
        var copyText = document.getElementById("newPassword");
        /* Select the text field */
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/

        /* Copy the text inside the text field */
        document.execCommand("copy");

        /* Alert the copied text */
        alert("Teks telah disalin : " + copyText.value);
    }

    captchaResult(text) {
        this.setState({
            captchaCode: text
        })
    }

    handleOnBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <>
                <Header title={"Lupa Password"} showBackButton onBack={this.handleOnBack}/>
                <ContainerLayout style={{marginTop:75,maxWidth:360}}>
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                        <>
                            <Card style={{marginTop : 20}}>
                                <CardContent>
                                    {this.state.showScreenNewPassword?(
                                        <Grid container style={{marginTop:10}} >
                                            <Grid item xs={12}>
                                                <Grid container justify="center">
                                                    <TextField
                                                        id="newPassword"
                                                        label="Password Baru Anda"
                                                        type="text"
                                                        name="newPassword"
                                                        margin="normal"
                                                        variant="outlined"
                                                        value={this.state.newPassword}
                                                    />

                                                    <Button disabled={this.state.showLoading} variant="contained" type="submit" onClick={()=>{
                                                        this.copy();
                                                    }}  size="large" color="primary" style={{margin : 10}}>
                                                        Salin Teks
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ):null}
                                    {this.state.showScreenSMSCode?(
                                        <Grid container style={{marginTop:10}} >
                                            <Grid item xs={12}>
                                                <Grid container justify="center">

                                                    <TextField
                                                        id="inputSmsCode"
                                                        label="Kode OTP"
                                                        type="number"
                                                        name="inputSmsCode"
                                                        // autoComplete="email"
                                                        margin="normal"
                                                        variant="outlined"
                                                        onChange={(e)=>{
                                                            this.setState({kodeOtp:e.target.value})
                                                        }}
                                                        value={this.state.kodeOtp}
                                                    />
                                                    

                                                    <Button disabled={this.state.showLoading} variant="contained" type="submit" onClick={()=>{
                                                        this.konfirmasiKodeOTP();
                                                    }}  size="small" color="primary" style={{margin : 10}}>
                                                        Ganti Password
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ):null}
                                    {this.state.showForgotScreen?(
                                        <Grid container style={{marginTop:10}} >
                                            <Grid item xs={12}>
                                                <Grid container justify="center">

                                                    <TextField
                                                        id="inputNoHp"
                                                        label="Nomor HP"
                                                        type="number"
                                                        name="nomorhp"
                                                        // autoComplete="email"
                                                        margin="normal"
                                                        variant="outlined"
                                                    />

                                                    <CaptchaTextGenerator result={this.captchaResult} />
                                                    <TextField
                                                        id="inputCaptcha"
                                                        label="Kode Captcha"
                                                        type="text"
                                                        name="kode_captcha"
                                                        margin="normal"
                                                        variant="outlined"
                                                    />

                                                    <Button disabled={this.state.showLoading} variant="contained" type="submit" onClick={()=>{
                                                        this.onLupaPassword();
                                                    }}  size="large" color="primary" style={{margin : 10}} endIcon={<SendIcon/>}>
                                                        Kirim
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ):null}
                                </CardContent>
                            </Card>
                        </>
                    </Grow>
                </ContainerLayout>
                <Snackbar
                    style={{marginTop:54}}
                    anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                    key={`bottom,right`}
                    open={this.state.showSnackBar}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.textSnackBar}
                />
            </>
        );
    }
}

export default (ForgotPassword);
