import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from '../../utils/Gvar';
import { sha256 } from 'js-sha256';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {loadProfile} from '../../utils/Utils';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Header from '../../components/navigation/Header';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

class Error404 extends Component {
    state = {
        title:''
    }
    constructor(props){
        super(props);
    }

    componentDidMount(){
        this.setState({title : 'Error 404'})
    }

    render() {
        // const classes = useStyles();
        const polygon = (
            <>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                        <Grid
                            item xs={12}
                            container
                            direction="column"
                            >
                         Halaman tidak ditemukan   
                        </Grid>
                    </CardContent>
                </Card> 
            </>
        );
                    
        return (
            <div>
                <Header title={"Error 404"} showBackButton onBack={()=>this.props.history.goBack()}/>
                <ContainerLayout style={{marginTop:75}}>
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </div>
        );
    }
}


export default Error404;
