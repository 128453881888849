import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Grow from '@material-ui/core/Grow';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from '../../../utils/Gvar';


class Pesan extends Component {

    constructor(props){
        super(props);
        this.state = ({
            jenisKartu : '',
            showSnackBar : false,
            textSnackBar : '',
            listPesan : [],
            showConfirmDialog : false,
            showConfirmPIN : false
        });
    }

    componentDidMount(){
        this.getDataPesan();
        // Gvar.NavigationTopContext.setState({title:'Inbox'});
    }

    getDataPesan(){
        // Gvar.appContext.setState({showLoading:true})
        try{
            var data_pesan = JSON.parse(localStorage.getItem('data_pesan'));
            this.setState({listPesan:data_pesan});
            if(data_pesan.length===0){
                this.setState({ showSnackBar: true, textSnackBar :"Tidak ada pesan"});
            }
            // Gvar.appContext.setState({showLoading:false})
        }catch(err){
            // Gvar.appContext.setState({showLoading:false})
            this.setState({ showSnackBar: true, textSnackBar :"Gagal saat mengambil data pesan"});
            console.error(err);
        }

    }


    render() {
        const polygon = (
            <div>
                {this.state.listPesan.length>0?(
                    <div>
                        <a style={{color:'blue'}} onClick={()=>{
                            localStorage.setItem('data_pesan','[]');
                            this.setState({listPesan:[]});
                        }}>Hapus semua pesan</a>
                        {this.state.listPesan.map((data,index) => (
                            <Card key={'itemproduk'+index} onClick={()=>{
                                //onclick
                            }} style={{marginTop : 20}}>
                                <CardContent style={{position:'relative'}}>
                                    <span style={{fontSize:12,fontWeight:'600'}}>
                                        {'dari ' + data.dari}    
                                    </span> <br></br>
                                    <span style={{color:'black'}}>
                                        {data.teks}
                                    </span>
                                    <span style={{position:'absolute',top:16,right:30,fontWeight:'400',fontSize:10}}>
                                        {data.tgl_pesan}
                                    </span>
                                    {/* <span style={{position:'absolute',top:16,right:0}}>
                                    <ArrowRightIcon></ArrowRightIcon> 
                                    </span> */}
                                </CardContent>
                            </Card>    
                        // <option key={data.id} value={data.kode_produk}>{data.nama_produk} ({data.h_jual})</option>
                        ))}
                    </div>
                ):null}
                
                <Snackbar
                    style={{marginTop:54}}
                    anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                    open={this.state.showSnackBar}
                    onClose={this.handleCloseConfirm}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.textSnackBar}</span>}
                />   
            </div>
          );
                    
        return (
            <div>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                {polygon}
                </Grow>
            </div>
        );
    }
}


export default Pesan;
