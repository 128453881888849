import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Gvar from '../utils/Gvar';
import { sha256 } from 'js-sha256';
import Utils from '../utils/Utils';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Divider, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Snackbar } from '@material-ui/core';
import { Close, FileCopy } from '@material-ui/icons';

export function TopBar(props) {
    var context = props.context
    return (
        <>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography component={'span'} variant="h6" >
                {context.state.title}
            </Typography>
            </Toolbar>
        </AppBar>
        </>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SuccessDialog = (props = {open:false, title:"", label: "", no: "", amount: "", payMethod:"", imgSrc:"", onClose:()=>{}, onClickCloseBtn:()=>{}}) => {
    const handleCopy = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
        } catch (err) {
        }
    };
    return (
    <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
        <AppBar>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={props.onClickCloseBtn} aria-label="close">
                    <Close />
                </IconButton>
                <Typography variant="h6">
                {props.title}
                </Typography>
            </Toolbar>
        </AppBar>
        <Box style={{marginTop:64}}>
            <img style={{width:200, margin:16}} alt={"icon "+props.payMethod} src={props.imgSrc}/>
            <List>
                <ListItem>
                    <ListItemText primary={props.label} secondary={props.no} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="copy" onClick={()=>{
                            handleCopy(props.no)
                        }}>
                            <FileCopy />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText primary="Jumlah Yang Harus Ditransfer" secondary={Utils.formatRupiah(props.amount)} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="copy" onClick={()=>{
                            handleCopy(props.amount)
                        }}>
                            <FileCopy />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText primary="Metode Pembayaran" secondary={props.payMethod} />
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemText primary="Catatan" secondary={
                        <React.Fragment>
                        <ul>
                            <li>Pastikan jumlah yang ditransfer sesuai dengan nominal diatas.</li>
                            <li>Nominal diatas termasuk angka Unik yang akan dikembalikan kedalam Saldo Anda saat Dana sampai ke sistem.</li>
                            <li>Jika mengalami kendala, Anda dapat mengubungi Customer Support Kami.</li>
                        </ul>
                        </React.Fragment>} />
                </ListItem>
                <Divider />
            </List>
        </Box>
    </Dialog>
    )
}

class TiketDeposit extends Component {
    state = {
        payMethods : [{name:"Virtual Account", code:"VA"}],
        payMethod : "",
        nominal:'',
        showConfirmDialog:false,
        showSnackBar:false,
        textSnackBar:"",
        title:"Topup Saldo",
        showLoading:false,
        showTokenInfo:false,
        tokenInfo:'',
        trxPesan:'',
        showSuccessDialog:false,
        channels: [],
        channel: "",
        admin:"",
        successDialog: {
            label: "", no: "", amount: "", payMethod:"", imgSrc:""
        }
    }
    constructor(props){
        super(props);
        this.data_member = JSON.parse(localStorage.getItem('data_member'));
        this.dataConfirm = {
            nominal:'',
            nama_produk : '',
            keterangan_produk : '',
            h_jual :'0'
        }
    }

    componentDidMount(){
       setTimeout(() => {
        this.nomRef.focus();
       }, 500);
    }

    getChannels(){
        var bodyJson = {
            token:"",
            timestamp:"2023-01-01 00:00:00",
            key:"ksd-app",
            pg_code:"NP"
        }

        bodyJson.token = sha256(bodyJson.timestamp + bodyJson.key);
        
        fetch(Gvar.server+'index.php/pub/payment/channel', {
            method: 'POST',
            body: JSON.stringify(bodyJson)
        })
        .then(res=>res.json()).then(res => {
            let filter = res.data.filter((item)=>item.payment_method === this.state.payMethod);
            this.setState({channels:filter})   
        }).catch(err => {
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            this.setState({showLoading:false})
        });
    }

    getAdmin(){
        var bodyJson = {
            total:this.state.nominal,
            payment_channel:"NICEPAY",
            metode_pembayaran: this.state.payMethod,
            channel_code: this.state.channel.split("-")[0]
        }
        
        fetch(Gvar.server+'index.php/pub/payment/admin', {
            method: 'POST',
            body: JSON.stringify(bodyJson)
        })
        .then(res=>res.json())
        .then(res => {
            this.setState({admin:res.data.admin})   
        }).catch(err => {
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", showLoading: false});
        });
    }

    submit (){
        this.setState({showLoading:true,showTokenInfo:false,tokenInfo:'',showConfirmDialog:false})

        var bodyJson = { 
            id_member: this.data_member.id,
            token : Utils.getMyToken(),
            nominal: this.state.nominal,
            channel_code: this.state.channel.split("-")[0],
            metode_pembayaran: this.state.payMethod
        }
        
        fetch(Gvar.server+'index.php/tiketdeposit/create_v2', {
            method: 'POST',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => {
            if(res.status_code === "200"){
                this.setState({showLoading:false, showSuccessDialog:true, nominal:"", payMethod:"", channel:"", channels:[], successDialog:{
                    label: res.data.label, no: res.data.no, amount: res.data.total, payMethod:res.data.payment_method+" "+res.data.channel_name, imgSrc:Gvar.pathImage+"np_"+res.data.channel_code.toLowerCase()+".png"
                }});
            }else{
                this.showToast(res.message);
                this.setState({showLoading:false});
            }
        }).catch(err => {
            this.showToast("Koneksi Gagal");
            this.setState({showLoading:false})
        })
    }

    onChangeNoPel = (e) => {
        if(e.target.value.length > 12){
            return;
        }
        this.setState({nominal:e.target.value});
    }

    showToast(text,autoClose=true){
        this.setState({ showSnackBar: true, textSnackBar :text});
        if(autoClose){
            setTimeout(() => {
                this.setState({ showSnackBar: false});
            }, 4000);
        }
    }

    copySerialNumb() {
        var range = document.createRange();
        range.selectNode(document.getElementById('serialNumb'));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
      
        this.showToast("Teks berhasil disalin");
    }

    render() {
        const polygon = (
            <>
                <Dialog
                    open={this.state.showConfirmDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.setState({ showSnackBar: false })}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Konfirmasi"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText component={"div"} id="alert-dialog-slide-description">
                            <table style={{fontSize:13}}>
                                <tbody>
                                    <tr>
                                        <td>Nominal</td>
                                        <td> : </td>
                                        <td style={{textAlign:"right",fontWeight:'bold'}}>{Utils.formatRupiah(this.state.nominal)}</td>
                                    </tr>
                                    <tr>
                                        <td>Metode Pembayaran</td>
                                        <td> : </td>
                                        <td style={{textAlign:"right",fontWeight:'bold'}}>{this.state.payMethod}</td>
                                    </tr>
                                    <tr>
                                        <td>Channel</td>
                                        <td> : </td>
                                        <td style={{textAlign:"right",fontWeight:'bold'}}>{this.state.channel.split("-")[1]}</td>
                                    </tr>
                                    <tr>
                                        <td>Admin</td>
                                        <td> : </td>
                                        <td style={{textAlign:"right",fontWeight:'bold'}}>{Utils.formatRupiah(this.state.admin,'.')}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={()=>{
                        this.setState({ showConfirmDialog: false });
                    }} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.submit()
                    }} color="primary">
                        Submit
                    </Button>
                    </DialogActions>
                </Dialog>
                <SuccessDialog 
                    open={this.state.showSuccessDialog} 
                    title='Tiket Deposit Berhasil Dibuat' 
                    amount={this.state.successDialog.amount}
                    payMethod={this.state.successDialog.payMethod}
                    label={this.state.successDialog.label}
                    no={this.state.successDialog.no}
                    imgSrc={this.state.successDialog.imgSrc}
                    onClickCloseBtn={()=>this.setState({showSuccessDialog:false})} 
                    onClose={()=>this.setState({showSuccessDialog:false})}/>
                <Card style={{marginTop : 20}}>
                    
                    <CardContent>
                    <Grid
                        item xs={12}
                        container
                        direction="column"
                        >
                            <TextField
                                id="nom"
                                label="Nominal"
                                type="number"
                                name="nominal"
                                autoComplete="none"
                                margin="normal"
                                variant="outlined"
                                value={this.state.nominal}
                                onChange={(e)=>this.setState({nominal:e.target.value})}
                                inputProps={{ maxLength: 7 }}
                                inputRef={ref=>this.nomRef=ref}
                            />

                            <FormControl variant="outlined" margin='normal'>
                                <InputLabel id="pilih-nominal">Pilih Metode Pembayaran</InputLabel>
                                <Select
                                    labelId="Pilih-metode-pembayaran"
                                    id="pilih-paymethod"
                                    value={this.state.payMethod}
                                    onChange={(e)=>{
                                        this.setState({payMethod:e.target.value}, ()=>{
                                            this.getChannels();
                                        })
                                    }}
                                    inputRef={ref=>this.payMethodRef=ref}
                                    label="Pilih Metode Pembayaran">
                                    <MenuItem value="">
                                        <em>--Pilih--</em>
                                    </MenuItem>
                                    {this.state.payMethods.map(data => (
                                        <MenuItem key={data.code} value={data.code}>{data.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl variant="outlined" margin='normal'>
                                <InputLabel id="pilih-channel">Pilih Channel</InputLabel>
                                <Select
                                    labelId="Pilih-channel"
                                    id="pilih-channel"
                                    value={this.state.channel}
                                    onChange={(e)=>{
                                        this.setState({channel:e.target.value}, ()=>{
                                            this.getAdmin();
                                        })
                                    }}
                                    inputRef={ref=>this.channelRef=ref}
                                    label="Pilih Metode Pembayaran">
                                    <MenuItem value="">
                                        <em>--Pilih--</em>
                                    </MenuItem>
                                    {this.state.channels.map(data => (
                                        <MenuItem key={data.code} value={data.code+'-'+data.name}>{data.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <Button variant="contained" type="submit" onClick={()=>{
                                this.dataConfirm.nominal = this.state.nominal;
                                this.setState({showTokenInfo:false});
                                if(this.state.nominal === ""){
                                    this.nomRef.focus();
                                    this.showToast("Nominal belum diisi.");
                                    return;
                                }else if(this.state.payMethod === ""){
                                    this.payMethodRef.focus();
                                    this.showToast("Metode Pembayaran belum dipilih.");
                                    return;
                                }else if(this.state.channel === ""){
                                    this.channelRef.focus();
                                    this.showToast("Channel belum dipilih.");
                                    return;
                                }
                                this.setState({showConfirmDialog: true});
                            }}  size="large" color="primary" style={{marginTop: 16, marginBottom: 16}}>
                                Lanjut
                            </Button>
                            <div style={{borderWidth:1,borderColor:'black',borderStyle:'dashed',padding:10,fontSize:11}}>INFO :<br></br> Saldo akan bertambah secara otomatis setelah sistem berhasil menerima dana.</div>
                        </Grid>
                    </CardContent>
                </Card>  
                {this.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null} 
                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                    open={this.state.showSnackBar}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.textSnackBar}</span>}
                />   
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{paddingTop:56}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}

export default TiketDeposit;
