import firebase from 'firebase';
// import Logger from './Logger';

export const initializeFirebase = () => {
  firebase.initializeApp({
    // taken from your project settings --> cloud messaging
    messagingSenderId: "843161986621",
    projectId : "kulasedaya",
    apiKey : "AIzaSyDRqvSUPdO9ffH3RG_ly0QBeLNNU7MxSvI",
    appId : "1:843161986621:android:9203505a8a15ce2dc36c54"
  });
}

export const askForPermissioToReceiveNotifications = async () => {
    try {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
      const token = await messaging.getToken();
      // console.log('TOKEN', token);

      fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${'promo'}`, {
            method: 'POST',
            headers: new Headers({
              Authorization: `bearer AAAAxFBe5j0:APA91bGH1EBF7bGX4gjAFOZb45QAnM9sxTn6V-5p6UH8sioMyfVpVeqAR2xlhcRrw8H5xMFrNXEQuyVmG_AQxY8DHynFs0cqbSlWUVDULVN9SZoKtAfNU9Zb3FsTkqggBSLetPQfl4ZG`
            })
          })
            .then((response) => {
              if (response.status < 200 || response.status >= 400) {
                console.log(response.status, response);
              }
              console.log(`"${'promo'}" is subscribed`);
            })
            .catch((error) => {
              console.error(error.result);
            });
          return true;
      
      return token;
    } catch (error) {
      console.error(error);
    }
  }