import React, { Component } from 'react';

import Container from '@material-ui/core/Container';
import { Box } from '@material-ui/core';
import Header from '../components/navigation/Header';

class MemberLayoutNoBottomTab extends Component {

    constructor(props){
        super(props);
    }

    handleOnBack = () => {
        this.props.history.goBack()
    };

    render() {
        return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header title={this.props.title} showBackButton onBack={this.handleOnBack}/>
            <div style={{ flex: 1, overflow: 'auto', paddingTop: 48}}>
                <Container maxWidth="sm">
                    {this.props.children}
                </Container>
            </div>
        </div>
        );
    }
}

export default MemberLayoutNoBottomTab;
