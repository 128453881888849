import React, { Component } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Drawer from './Drawer';
import { BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom'
import Gvar from './utils/Gvar';
import Login from './pages/login/Login';
// import Home from './pages/tab/home/Home';
import Daftar from './pages/daftar/Daftar';
import Logger from './utils/Logger';
import ForgotPassword from './pages/forgot_password/ForgotPassword';

import ProdukPrefix from './pages/ProdukPrefix';
import ProdukPembayaran from './pages/ProdukPembayaran';
import TokenListrik from './pages/TokenListrik';
import TokenListrikTopup from './pages/TokenListrikTopup';
import PLNinquiry from './pages/PLNinquiry';
import PLNbayar from './pages/PLNbayar';

import Pembayaran from './pages/Pembayaran';
import PengaturanGantiPassword from './pages/PengaturanGantiPassword';
import PengaturanGantiPIN from './pages/PengaturanGantiPIN';
import ProdukGroup from './pages/ProdukGroup';
import ProdukNonPrefix from './pages/ProdukNonPrefix';

import ErrorScreen from './pages/error/ErrorScreen';
import ProdukAll from './pages/ProdukAll';
import TopUpDeposit from './pages/TopUpDeposit';
import BannerDetail from './pages/BannerDetail';
import BindingAccount from './pages/BindingAccount';
import ProdukBank from './pages/ProdukBank';
import HargaJual from './pages/HargaJual';
import TiketDeposit from './pages/TiketDeposit';
import AuthLayout from './layouts/AuthLayout';
import MemberLayout from './layouts/MemberLayout';
import PrivateRoute from './utils/PrivateRoute';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './themes/Theme';
import Error404 from './pages/error/Error404';
import MemberLayoutNoBottomTab from './layouts/MemberLayoutNoBottomTab';
// import Dashboard from './pages/tab/home/Home';
class App extends Component {
	
  	render() {
		return (
			<ThemeProvider theme={theme}>
				<Router basename={Gvar.baseName}>
					<Switch>
						{/* Auth Routes */}
						<Route
							path="/login"
							render={(props) => (
								<AuthLayout>
									<Login {...props} />
								</AuthLayout>
							)}
						/>
						<Route
							path="/lupapassword"
							render={(props) => (
								<AuthLayout>
									<ForgotPassword {...props} />
								</AuthLayout>
							)}
						/>
						<Route
							path="/daftar"
							render={(props) => (
								<AuthLayout>
									<Daftar {...props} />
								</AuthLayout>
							)}
						/>

						<Route path="/binding-account" component={BindingAccount}/>

						{/* Member Routes */}

						<PrivateRoute path="/" exact component={MemberLayout}/>

						<PrivateRoute path="/group" component={ProdukGroup}/>

						<PrivateRoute path="/allproduct" component={ProdukAll}/>

						<PrivateRoute path="/pembayaran" component={Pembayaran}/>
						<PrivateRoute path="/tokenlistrik" component={TokenListrik}/>
						<PrivateRoute path="/tokenlistriktopup" component={TokenListrikTopup}/>
						<PrivateRoute path="/plninquiry" component={PLNinquiry}/>
						<PrivateRoute path="/plnbayar" component={PLNbayar}/>
						<PrivateRoute path="/gantipassword" component={PengaturanGantiPassword}/>
						<PrivateRoute path="/gantipin" component={PengaturanGantiPIN}/>
						<PrivateRoute path="/produknonprefix" component={ProdukNonPrefix}/>
						<PrivateRoute path="/pulsa" component={ProdukPrefix}/>
						<PrivateRoute path="/error" component={ErrorScreen}/>
						<PrivateRoute path="/paketdata" component={ProdukPrefix}/>
						<PrivateRoute path="/pakettelepon" component={ProdukPrefix}/>
						<PrivateRoute path="/topupdeposit" component={TopUpDeposit}/>
						<PrivateRoute path="/produkpembayaran" component={ProdukPembayaran}/>
						<PrivateRoute path="/bannerdetail" component={BannerDetail}/>
						<PrivateRoute path="/transferbank" component={ProdukBank}/>
						<PrivateRoute path="/updateharga" component={HargaJual}/>
						<PrivateRoute path="/topup-saldo" component={TiketDeposit}/>

						<Route
							path="/*"
							render={(props) => (
								<AuthLayout>
									<Error404 {...props} />
								</AuthLayout>
							)}
						/>
					</Switch>
				</Router>
			</ThemeProvider>
		);
	}
}

export default (App);
