import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from '../utils/Gvar';
import { sha256 } from 'js-sha256';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Utils, {loadProfile} from '../utils/Utils';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Logger from '../utils/Logger';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    return (
        <div className={classes.root}>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
        </AppBar>
        </div>
    );
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProdukAll extends Component {
    state = {
        jenisKartu : '',
        showSnackBar : false,
        textSnackBar : '',
        listProduk : [],
        showConfirmDialog : false,
        showConfirmPIN : false,
        title:'',
        showLoading : false,
        list_jenis : [],
        list_group : [],
        jenis : '',
        group : ''
    }
    constructor(props){
        super(props);
        this.jenisKartu = "";
        this.jenis = "";
        this.titlePage = "Daftar Produk";
        this.dataConfirm = {
            nama_produk : ''
        }
         
        this.refPIN = React.createRef();
    }

    componentDidMount(){
        this.setState({title :this.titlePage})
        loadProfile();
        this.getDataProduk();
        this.getkategori();
        this.getProdukGroup();
    }

    handleClickOpenConfirm(rowData){
        Logger.d("rowData",rowData);
        loadProfile();
        this.dataConfirm = rowData;
        this.sisaSaldo = parseInt(Gvar.saldo)-parseInt(rowData.h_jual)
        this.setState({showConfirmDialog:true});
    };
    
    handleCloseConfirm = () => {
        this.setState({showConfirmDialog:false});
    };

    handleChange = event => {
        // this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    getDataProduk(){
        this.setState({showLoading:true})
        var data_member = JSON.parse(localStorage.getItem('data_member'));
        
        var bodyJson = {jenis:this.state.jenis,group : this.state.group,id_member:data_member.id,token:Utils.getMyToken()}
        Logger.d(bodyJson);
        fetch(Gvar.server+'index.php/produk/all', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //Logger.d(res)
                this.setState({listProduk:res.data})
                this.setState({showLoading:false});
            }
            ).catch(err => {
                this.setState({showLoading:false});
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
             })
    }

    async sendTransaction (){
        var data_member = localStorage.getItem('data_member');
        var noTujuan = document.getElementById('inputNoTujuan').value;
        var kodeProduk = this.dataConfirm.kode_produk;
        var pinTransaksi = document.getElementById('inputPINTransaksi').value;
        if(noTujuan === ""){
            this.setState({ showSnackBar: true, textSnackBar : "no tujuan belum diisi"}); return;
        }
        if(kodeProduk === ""){
            this.setState({ showSnackBar: true, textSnackBar : "nominal tujuan belum diisi"}); return;
        }
        if(pinTransaksi === ""){
            this.setState({ showSnackBar: true, textSnackBar : "pin transaksi belum diisi"}); return;
        }
        this.setState({showLoading:true})
        var bodyJson = { 
            id_member: JSON.parse(data_member).id,
            no_tujuan: noTujuan,
            kode_produk:kodeProduk,
            pin_transaksi:sha256(pinTransaksi),
            token:Utils.getMyToken()
        }
        Logger.d(bodyJson);
        await fetch(Gvar.server+'index.php/transaksi', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //Logger.d(res)
                // this.setState({listProduk:res})
                this.setState({ showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                this.setState({showLoading:false})
                if(res.status === "SUKSES"){
                    document.getElementById("inputNoTujuan").value = "";
                }
            }
            ).catch(err => {
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
                this.setState({showLoading:false})
             })
    }

    formatRupiah(angka, prefix){
        if(angka!==undefined && angka!== null && angka !== ''){
            angka = angka.toString();
            var number_string = angka.replace(/[^,\d]/g, '').toString(),
            split   		= number_string.split(','),
            sisa     		= split[0].length % 3,
            rupiah     		= split[0].substr(0, sisa),
            ribuan     		= split[0].substr(sisa).match(/\d{3}/gi);

            // tambahkan titik jika yang di input sudah menjadi angka ribuan
            if(ribuan){
                var separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }

            rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
            return prefix === undefined ? rupiah : (rupiah ? 'Rp ' + rupiah : '');
        }else{
            return '';
        }
    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    getkategori(){
        var bodyJson = {id_member:Utils.getDataMember().id,token:Utils.getMyToken()};
        fetch(Gvar.server+'index.php/produk/kategori-utama', {
          method: 'post',
          body: JSON.stringify(bodyJson),
          }).then(res=>res.json()
          ).then(res => {
              // Logger.d(res)
              var baseCategory = res.data;
              this.setState({list_jenis:baseCategory}); 
              // this.setState({listProduk:res})
          }).catch(err => {
              // this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
              this.setState({showLoading:false})
          })
      }

    getProdukGroup(){
        this.setState({ showSnackBar: false, textSnackBar :""});
        var jenis = this.state.jenis;
        var bodyJson = { 
            jenis: jenis,
            id_member:Utils.getDataMember().id,
            'token':Utils.getMyToken()
        }
        fetch(Gvar.server+'index.php/produk/group', {
            method: 'POST',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
        ).then(res => {
            // Logger.d(res)
            this.setState({list_group:res.data}); 
        }).catch(err => {
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            this.setState({showLoading:false})
        })
    }

    handleChangeSelect = async (event) =>{
        console.log(event.target.value);
        await this.setState({jenis:event.target.value,group:'',list_group:[]});
        await this.getProdukGroup();
    }
    
    handleChangeSelectGroup = async (event) =>{
        await this.setState({group:event.target.value});
        await this.getDataProduk();
    }
    render() {
        // const classes = useStyles();
        const polygon = (
            <div>
                <Dialog
                    open={this.state.showConfirmDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Konfirmasi"}</DialogTitle>
                    <DialogContent>
                        <table>
                            <tbody>
                                <tr>
                                    <td>No Tujuan</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.nohp}</td>
                                </tr>
                                <tr>
                                    <td>Produk</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.dataConfirm.nama_produk}</td>
                                </tr>
                                <tr>
                                    <td>Keterangan Produk</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.dataConfirm.keterangan_produk}</td>
                                </tr>
                                <tr>
                                    <td>Harga</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.formatRupiah(this.dataConfirm.h_jual,'Rp ')}</td>
                                </tr>
                                <tr>
                                    <td>Saldo</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.formatRupiah(Gvar.saldo,'Rp ')}</td>
                                </tr>
                                <tr>
                                    <td>Sisa Saldo</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.formatRupiah(this.sisaSaldo,'Rp ')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleCloseConfirm} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:true,showConfirmDialog:false})
                        setTimeout(() => {
                            document.getElementById('inputPINTransaksi').focus();
                        }, 500);
                    }} color="primary">
                        Lanjut
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showConfirmPIN}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseConfirm}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"PIN Transaksi"}</DialogTitle>
                    <DialogContent>
                        <TextField
                                id="inputPINTransaksi"
                                label="PIN Transaksi"
                                type="password"
                                name="password"
                                margin="normal"
                                variant="outlined"
                                autoFocus
                                innerRef={this.refPIN}
                            />
                    </DialogContent>
                    <DialogActions>
                    <Button disabled={this.state.showLoading} onClick={()=>{
                        this.setState({showConfirmPIN:false});
                    }} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmPIN:false});
                        this.sendTransaction();
                        document.getElementById('inputPINTransaksi').value ="";
                    }} color="primary">
                        Beli
                    </Button>
                    </DialogActions>
                </Dialog>
                {this.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null}
                {/* <select onChange={this.handleChangeSelect} value={this.state.jenis}>
                    <option value="">Semua Jenis</option>
                    {this.state.list_jenis.map((data,index)=>{
                        return (<option value={data.jenis}>{data.jenis.replace(/_/g,' ')}</option>);
                    })}
                </select> */}
                <FormControl style={{
                    margin:4,
                    minWidth: 115
                }}>
                    <InputLabel id="pilih-jenis" style={{fontSize:13}}>Jenis</InputLabel>
                    <Select
                        labelId="pilih-jenis"
                        id="pilih-jenis-select"
                        value={this.state.jenis}
                        onChange={this.handleChangeSelect}
                        style={{fontSize:11}}
                    >
                        {this.state.list_jenis.map((data,index)=>{
                            return (
                                <MenuItem value={data.jenis}>{data.jenis.replace(/_/g,'')}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl style={{
                    margin:4,
                    minWidth: 175,
                }}>
                    <InputLabel id="pilih-group" style={{fontSize:13}}>Group Produk</InputLabel>
                    <Select
                        labelId="pilih-group"
                        id="pilih-group-select"
                        value={this.state.group}
                        onChange={this.handleChangeSelectGroup}
                        style={{fontSize:11}}
                    >
                        {this.state.list_group.map((data,index)=>{
                            return (
                                <MenuItem value={data.group_produk}>{data.group_produk.replace(/_/g,'')}</MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                {/* <select onChange={this.handleChangeSelectGroup} value={this.state.group}>
                    <option value="">Semua Group Produk</option>
                    {this.state.list_group.map((data,index)=>{
                        return (<option value={data.group_produk}>{data.group_produk.replace(/_/g,'')}</option>);
                    })}
                </select> */}
                {this.state.listProduk.map((data,index) => (
                    <Card 
                    // onClick={()=>{
                    //     this.setState({ showSnackBar: false})
                    //     this.handleClickOpenConfirm(data)
                    // }} 
                    key={data.kodeProduk} style={{marginTop : 20}}>
                        <CardContent style={{position:'relative'}}>
                            <span style={{fontSize:18}}>
                                {data.nama_produk}    
                            </span> <br></br>
                            <span>
                            {data.keterangan_produk}
                            </span>
                            <span style={{position:'absolute',top:16,right:30}}>
                                {this.formatRupiah(data.h_jual,'Rp ')} 
                            </span>
                            <span style={{position:'absolute',top:16,right:0}}>
                            {/* <ArrowRightIcon></ArrowRightIcon>  */}
                            </span>
                        </CardContent>
                    </Card>    
                // <option key={data.id} value={data.kode_produk}>{data.nama_produk} ({data.h_jual})</option>
                ))}
            </div>
          );
                    
        return (
            <div>
                {/* <AppBar position="fixed">
                    <Toolbar variant="dense">
                            {this.state.title}
                        
                    </Toolbar>
                    <Snackbar
                        style={{marginTop:56}}
                        anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                        open={this.state.showSnackBar}
                        onClose={this.handleCloseSnackBar}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                        }}
                        message={<span id="message-id">{this.state.textSnackBar}</span>}
                    />   
                </AppBar> */}
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{marginTop:75}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </div>
        );
    }
}


export default ProdukAll;
