import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grow from '@material-ui/core/Grow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Gvar from '../../../utils/Gvar';
import { Box, CardHeader, DialogActions, DialogContent, ListItemIcon } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Utils from '../../../utils/Utils';
// import ReactHtml from 'raw-html-react';
// import Logger from '../../../utils/Logger';
import {Done, DoneAll, ErrorOutline } from '@material-ui/icons';
import { format, parse } from 'date-fns';
class Histori extends Component {
    
    state = {
        listHistori : [],
        selectedStartDate: this.formatDate(new Date()),
        selectedEndDate: this.formatDate(new Date()),
        startDate : "",
        endDate : "",
        inputNoTujuan : "",
        openDialogDetail : false,
        openDialogPrint : false,
        kodeProduk : "",
        noTujuan : "",
        total : "",
        saldoAkhir : "",
        pesan : "",
        sn : "",
        requestId : "",
        printText : "",
        status: "",
        admin: "",
        fee: "",
        bill: "",
        h_jual:""
    }

    componentDidMount(){
       this.getDataHistori();
    }

    getDataHistori(){
        // Gvar.homeContext.setState({showLoading:true,showSnackBar: false})
        var bodyJson = {
            start_date : this.state.selectedStartDate,
            end_date : this.state.selectedEndDate,
            no_tujuan : this.state.inputNoTujuan,
            id_member : Gvar.dataMember.id,
            status : "",
            token : Utils.getMyToken()
        }
        // Logger.d(bodyJson);
        fetch(Gvar.server+'index.php/histori/transaksi', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        })
        .then(res=>res.json())
        .then(res => {
            this.setState({listHistori:res.data}); 
            // Gvar.homeContext.setState({showLoading:false});
            // if(res.length === 0){
            //     Gvar.homeContext.setState({ showSnackBar: true, textSnackBar :"Tidak ada transaksi"});
            // }
        }).catch(err => {
            // Logger.d(err.toString());
            // Gvar.homeContext.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", showLoading:false});
        })
    }

    handleStartDateChange = date => {
        const rawDate = date.target.value; // Ini dalam format YYYY-MM-DD
        const formattedDate = format(parse(rawDate, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd'); // Memastikan format YYYY-MM-DD
        this.setState({ selectedStartDate: formattedDate });
    };

    handleEndDateChange = date => {
        const rawDate = date.target.value; // Ini dalam format YYYY-MM-DD
        const formattedDate = format(parse(rawDate, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd'); // Memastikan format YYYY-MM-DD
        this.setState({ selectedEndDate: formattedDate });
    };

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    handleChangeInputNoTujuan = event => {
        const { value } = event.target;
        const textReturn = value.slice(0, 20);

        this.setState({
            inputNoTujuan : textReturn
        });
    };

    openDetail(data){
        this.setState({
            openDialogDetail : true,
            kodeProduk : data.kode_produk,
            noTujuan : data.no_tujuan,
            total : data.total,
            saldoAkhir : data.saldo_akhir,
            pesan : data.pesan,
            requestId : data.id_request,
            sn : data.sn,
            status: data.status,
            admin: data.admin,
            fee: data.fee,
            bill: data.bill,
            h_jual: data.h_jual
        });
    }

    getStruk() {
        // Gvar.homeContext.setState({showLoading:true,showSnackBar: false})
        this.setState({printText:""})
        var bodyJson = {
            request_id : this.state.requestId,
            id_member : Gvar.dataMember.id,
            token : Utils.getMyToken()
        }
        
        fetch(Gvar.server+'index.php/struk', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => { 
            try {
                this.setState({printText:res.data.text.replace(/\n/g,"\n\r")})
                // Gvar.homeContext.setState({showLoading:false});
            } catch (error) {
                // Gvar.homeContext.setState({ showSnackBar: true, textSnackBar :"Gagal mengambil data struk", showLoading:false});
            }
        }).catch(err => {
            // Gvar.homeContext.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal", showLoading:false});
        })
    }

    printText(text){
        var printContents = text.replace(/\n/g,"<br>");
        // var originalContents = document.body.innerHTML;
   
        // document.body.innerHTML = printContents;
   
        // window.print();
   
        // document.body.innerHTML = originalContents;
        // document.body.style.fontFamily = "monospace"

        const iframe = document.createElement('iframe')
        iframe.setAttribute('title', "Print Struk")
        iframe.setAttribute('id', "Print")
        iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;font-family:monospace,monospace !IMPORTANT')
        document.body.appendChild(iframe);
        let pri = iframe.contentWindow

        pri.document.open()
        pri.document.write(printContents)
        pri.document.close()
        pri.focus()
        pri.print()
    }


    render() {

        const { selectedStartDate, selectedEndDate } = this.state;
        const polygon = (
            <>
            <style>
            {`
                table,th,td{
                border:0.5px solid black;
                }
                #printtext{
                    font-family:monospace,monospace !IMPORTANT
                }
            `}
            </style>
            <Dialog open={this.state.openDialogDetail} scroll='paper' aria-labelledby="Detail">
                <DialogTitle id="simple-dialog-title" >Detail</DialogTitle>
                <DialogContent>
                    <List>
                        <ListItem>
                            <ListItemText primary="Ref ID" secondary={this.state.requestId} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Kode Produk" secondary={this.state.kodeProduk} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="No Tujuan" secondary={this.state.noTujuan} />
                        </ListItem>
                        {this.state.kodeProduk.substring(0,3)=="PAY"?(
                            <>
                            <ListItem>
                                <ListItemText primary="Admin" secondary={Utils.formatRupiah(this.state.h_jual)} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Fee" secondary={Utils.formatRupiah(this.state.fee)} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Tagihan" secondary={Utils.formatRupiah(this.state.bill)} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Total" secondary={Utils.formatRupiah(this.state.total)} />
                            </ListItem>
                            </>
                        ):(
                            <>
                            <ListItem>
                                <ListItemText primary="Harga" secondary={Utils.formatRupiah(this.state.h_jual)} />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Total" secondary={Utils.formatRupiah(this.state.total)} />
                            </ListItem>
                            </>
                        )}
                        
                        <ListItem>
                            <ListItemText primary="Saldo Akhir" secondary={Utils.formatRupiah(this.state.saldoAkhir)} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Status" secondary={this.state.status} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Message" secondary={this.state.pesan} />
                        </ListItem>
                        {this.state.kodeProduk.substring(0,3)!="PAY"?(
                            <ListItem>
                                <ListItemText primary="SN" secondary={this.state.sn} />
                            </ListItem>
                        ):null}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button color='primary' style={{float:'right',margin:5}} variant="text" onClick={()=>{
                        this.setState({openDialogDetail : false});
                    }}>Tutup</Button>
                    <Button color='primary' variant="text" onClick={()=>{
                        this.getStruk();
                        this.setState({openDialogPrint : true});
                    }}>Cetak</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.openDialogPrint} scroll='paper' aria-labelledby="Detail">
                <DialogTitle id="print-dialog" >Cetak</DialogTitle>
                <DialogContent>
                    {this.state.printText!==""?(
                        <div id="printtext">
                            <Box component={"pre"}>{this.state.printText}</Box>
                        </div>
                    ):null}
                </DialogContent>
                <DialogActions>
                    <Button color='primary' style={{float:'right',margin:5}} variant="text" onClick={()=>{
                        this.setState({openDialogPrint : false});
                    }}>Tutup</Button>
                    <Button color='primary' variant="text" onClick={()=>{
                        this.printText(this.state.printText);
                    }}>Cetak</Button>
                </DialogActions>
            </Dialog>
            <Card>
                <CardHeader title={'Filter'}/>
                <CardContent>
                    <TextField
                        id="date"
                        label="Start Date"
                        type="date"
                        defaultValue={selectedStartDate}
                        onChange={this.handleStartDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        style={{marginRight:12}}
                    />
                    <TextField
                        id="date"
                        label="End Date"
                        type="date"
                        defaultValue={selectedEndDate}
                        onChange={this.handleEndDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        margin="dense"
                    />
                    <TextField
                            onChange={this.handleChangeInputNoTujuan}
                            value={this.state.inputNoTujuan}
                            id="no-tujuan"
                            label="No Tujuan / ID"
                            type="number"
                            name="notujuan"
                            margin="dense"
                            variant="outlined"
                        />
                        <br/>
                    <Button style={{marginTop:8}} variant="contained" onClick={()=>{
                        this.getDataHistori();
                    }}>Cari</Button>
                </CardContent>
            </Card>
            <Card style={{marginTop : 8}}>
                <CardHeader title={'Daftar Transaksi'}/>
                <CardContent>
                    <List>
                    {this.state.listHistori.map( (data) => {
                        if(data.status === 'SUKSES' || data.status === 'PENDING' || data.status === 'GAGAL'){
                            return (
                                <ListItem key={data.id}
                                    button
                                    // selected={this.state.selectedIndex === 2}
                                    onClick={()=>this.openDetail(data)}
                                    >
                                        <ListItemIcon>
                                            {data.status === 'SUKSES'?<DoneAll />:(data.status === 'PENDING'?<Done />:<ErrorOutline />)}
                                        </ListItemIcon>
                                        {/* <ListItemText primary={data.no_tujuan+' . '+data.kode_produk+' . '+data.status+' . '+(data.pesan.replace(/<br>/g,"\n"))+(data.kode_produk.startsWith('PLN')?(', Token : '+data.sn.replace(/\//g,' ')):'')} secondary={data.tgl_transaksi+', harga = Rp '+this.numberWithCommas(data.h_jual)+', saldo = Rp '+this.numberWithCommas(data.saldo_akhir)}/> */}
                                        <ListItemText primary={data.no_tujuan + ' - '+data.status}  secondary={data.tgl_transaksi}/>
                                </ListItem>
                            );
                        }else{
                            return null;
                        }
                    })}
                    {this.state.listHistori.length == 0?(
                        <ListItem>
                            <ListItemText primary={"Belum ada transaksi"}/>
                        </ListItem>
                    ):null}
                    </List>
                </CardContent>
            </Card> 
            </>
        );
                    
        return (
            <>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                {polygon}
                </Grow>
            </>
        );
    }
}

export default Histori;
