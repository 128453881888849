import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Snackbar from '@material-ui/core/Snackbar';
import Gvar from '../utils/Gvar';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Utils, {loadProfile} from '../utils/Utils';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Logger from '../utils/Logger';
import { Divider, FormControl, InputLabel, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    var currentURL = window.location.href;
    // loadProfile();
    var jenis = currentURL.split("jenis=")[1];
    return (
        <div className={classes.root}>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
            <Snackbar
                style={{marginTop:56}}
                anchorOrigin={{ vertical: 'top', horizontal :'right' }}
                open={context.state.showSnackBar}
                onClose={context.handleCloseSnackBar}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{context.state.textSnackBar}</span>}
            />
        </AppBar>
        </div>
    );
}


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ProdukBank extends Component {
    state = {
        jenisKartu : '',
        showSnackBar : false,
        textSnackBar : '',
        listProduk : [],
        showConfirmDialog : false,
        showConfirmPIN : false,
        title : 'Bank Transfer',
        noTujuan : '',
        pinTransaksi : '',
        inputData : [],
        banks: [],
        bankCode: '',
        bankName: '',
        noRek: '',
        nominal: '',
        berita: '',
        penyedia: '',
        admin: '',
        fee: '',
        kodeProduk: '',
        atasNama: ''
    }
    constructor(props){
        super(props);
        this.jenisKartu = "";
        this.jenis = "";
        this.titlePage = "";
        // loadProfile();
        this.data_member = JSON.parse(localStorage.getItem('data_member'));
        this.jenis = "TRANSFER_BANK";
        this.group = "";
        this.titlePage = this.group;
        this.dataConfirm = {
            nama_produk : ''
        }
    }

    componentDidMount(){
        loadProfile();

        // setTimeout(() => {
        //     this.refNoTujuan.focus();
        // }, 500);

        this.getDataProduk();
        this.getBankList();

    }

    handleCloseSnackBar = () =>{
        this.setState({ showSnackBar: false, textSnackBar :""});
    }

    handleClickOpenConfirm(rowData){
        this.setState({showSnackBar: false})

        if(this.state.noRek === ""){
            this.setState({ showSnackBar: true, textSnackBar : "Nomor rekening harus diisi"});
            this.noRekRef.focus();
            return;
        }

        if(this.state.nominal === ""){
            this.setState({ showSnackBar: true, textSnackBar : "Nominal harus diisi"});
            this.nominalRef.focus();
            return;
        }

        if(this.state.bankCode === ""){
            this.setState({ showSnackBar: true, textSnackBar : "Belum memilih bank"});
            this.bankRef.focus();
            return;
        }

        this.setState({penyedia: rowData.nama_produk, admin: rowData.h_jual, fee: rowData.fee, kodeProduk: rowData.kode_produk,showConfirmPIN:true});
        setTimeout(() => {
            this.refPIN.focus();
        }, 500);
    };
    
    handleCloseConfirm = () => {
        this.setState({showConfirmDialog:false});
    };

    handleChange = event => {
        // this.setState({ [event.target.name]: event.target.value });
    };

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    getDataProduk(){
        this.setState({showLoading:true})
        var bodyJson = {jenis:this.jenis,group : this.group,id_member:this.data_member.id,token:Utils.getMyToken()}
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/produk/nonprefix', {
        method: 'post',
        body: JSON.stringify(bodyJson)
        }).then(res=>res.json()
            ).then(res => {
                //console.log(res)
                this.setState({listProduk:res.data})
                this.setState({showLoading:false});

                if(res.data.length > 0){
                    console.log(res.data[0].input_data)
                    if(res.data[0].input_data === null){
                        this.setState({inputData: [{type:'text', name:'no_tujuan', label:'No Tujuan', placeholder:''}]});
                    }else{
                        this.setState({inputData: JSON.parse(res.data[0].input_data)});
                    }
                }
            }
            ).catch(err => {
                this.setState({showLoading:false});
                this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
             })
    }

    getBankList(){
        this.setState({showLoading:true})
        var bodyJson = {id_member:this.data_member.id,token:Utils.getMyToken()}
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/vendor/nextrans/bank_list', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => {
            this.setState({banks:res.data})
            this.setState({showLoading:false});
        }).catch(err => {
            this.setState({showLoading:false});
            this.setState({showSnackBar: true, textSnackBar :"Koneksi Gagal"});
        })
    }

    sendTransaction (isInq = false, callback = () => {}){
        this.setState({showLoading:true})
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        var bodyJson = { 
            id_request: null, 
            id_member: this.data_member.id, 
            token: Utils.getMyToken(), 
            no_tujuan: this.state.noRek,
            kode_produk: isInq?'INQBANK':this.state.kodeProduk,
            kode_bank: isInq?this.state.bankCode.split("-")[1]:(this.state.penyedia == 'Flip'?this.state.bankCode.split("-")[1]:this.state.bankCode.split("-")[0]),
            nominal: this.state.nominal,
            keterangan: this.state.berita,
            pin_transaksi: Utils.getHash(this.state.pinTransaksi),
            trx_source : "WEB_APP",
            pg_code: null,
            metode_pembayaran : "EWALLET",
            channel_code:"SALDO",
            draf: isInq?0:(localStorage.getItem("bindingAcc")?1:0),
            inv_info_pembayaran : {
                nama_pengirim : Gvar.appName,
                alamat_pengirim : "Bekasi",
                email_pengirim : Gvar.senderMail,
                link_belanja_pengirim: "",
                no_wa_pengirim : Gvar.whatsappNumber,
                hotline_pengirim : Gvar.hotlineSender,
                web_pengirim : Gvar.webSender,
                nama_penerima : this.state.atasNama,
                alamat_penerima : "",
                email_penerima : "",
                no_hp_penerima : "",
                nama_ewallet : "Saldo"
            }
        }

        fetch(Gvar.server+'index.php/transaksi', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => {
            loadProfile();
            if(res.data.status === "SUKSES"){
                if(isInq){
                    this.setState({showLoading: false});
                }else{
                    this.setState({showLoading: false, showSnackBar: true, textSnackBar: res.message});
                    // clear input
                    this.setState({noRek: '', nominal: '', bankCode: '', berita: '', pinTransaksi: ''})
                }
            }else if(res.data.status === "PENDING" || res.data.status === "LUNAS"){
                if(isInq){
                    this.setState({showLoading: false, showSnackBar: true, textSnackBar: res.message});
                }else{
                    this.setState({showLoading: false, showSnackBar: true, textSnackBar: res.message});
                    // clear input
                    this.setState({noRek: '', nominal: '', bankCode: '', berita: '', pinTransaksi: ''})
                }
                
                // Gvar.checkStatusService = setInterval(() => {
                //     this.checkStatus(res.data.id_request);
                // }, 2000);
            }else if(res.data.status === "DRAF"){
                this.setState({showLoading: false, showSnackBar: true, textSnackBar: res.message});
                // clear input
                this.setState({noRek: '', nominal: '', bankCode: '', berita: '', pinTransaksi:''})
            }else{
                this.setState({showLoading: false, showSnackBar: true, textSnackBar : res.data.pesan});
            }
            callback(res);
        }).catch(err => {
            Logger.e(err.message);
            this.setState({showSnackBar: true, textSnackBar: "Koneksi Gagal", showLoading: false});
            callback(false);
        })
    }

    checkStatus(idRequest){
        this.setState({showLoading: true})
        var bodyJson = { 
            id_member: this.data_member.id,
            token : Utils.getMyToken()
        }
        // console.log(bodyJson);
        fetch(Gvar.server+'index.php/transaksi/cek/'+idRequest, {
            method: 'post',
            body: JSON.stringify(bodyJson)
        }).then(res=>res.json()).then(res => { 
            if(Gvar.homeContext!=null){
                Gvar.homeContext.pullMessage(); 
            }   
            //console.log(res)
            // this.setState({listProduk:res})
            try {
                if(res.data.status === "SUKSES" || res.data.status === "GAGAL"){
                    loadProfile();
                    clearInterval(Gvar.checkStatusService);
                    this.setState({showLoading:false,showSnackBar: true, textSnackBar : res.data.status+", "+res.data.pesan});
                    if(res.data.status === "SUKSES"){
                        this.setState({noTujuan:''})
                    }
                }
            } catch (error) {
                Logger.e(error.message)
            }
        }).catch(err => {
            Logger.d(err.message);
            // clearInterval(Gvar.checkStatusService);
            this.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            this.setState({showLoading:false})
        });
    }

    onSubmitPin = () => {
        if(this.state.pinTransaksi === ""){
            this.refPIN.focus();
            this.setState({ showSnackBar: true, textSnackBar : "PIN transaksi belum diisi"}); return;
        }
        this.setState({showConfirmPIN:false});
        this.sendTransaction(true,(resp)=>{
            if((resp.status_code ?? "500") === "200"){
                let atasNama = "";
                try{
                    atasNama = resp.data.pesan.split("NAMA      : ")[1].split("\n")[0];
                }catch(e){
                    atasNama = "Cek Nama Rekening Gagal"
                }
                this.setState({showConfirmDialog:true, atasNama:atasNama});
            }
        });
    }

    render() {
        const polygon = (
            <>
                <Dialog
                    open={this.state.showConfirmDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"Konfirmasi"}</DialogTitle>
                    <DialogContent>
                    <table>
                            <tbody>
                                <tr>
                                    <td>No Rekening</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.state.noRek}</td>
                                </tr>
                                <tr>
                                    <td>Atas Nama</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.state.atasNama}</td>
                                </tr>
                                <tr>
                                    <td>Nominal</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.state.nominal}</td>
                                </tr>
                                <tr>
                                    <td>Transfer Ke Bank</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.state.bankName}</td>
                                </tr>
                                <tr>
                                    <td>Berita</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.state.berita}</td>
                                </tr>
                                <tr>
                                    <td>Penyedia Layanan</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{this.state.penyedia}</td>
                                </tr>
                                <tr>
                                    <td>Admin</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{Utils.formatRupiah(this.state.admin,'.')}</td>
                                </tr>
                                <tr>
                                    <td>Fee</td>
                                    <td> : </td>
                                    <td style={{textAlign:"right"}}>{Utils.formatRupiah(this.state.fee,'.')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.handleCloseConfirm} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        this.setState({showConfirmDialog:false});
                        this.sendTransaction();
                    }} disabled={this.state.atasNama.includes("Rekening Gagal")} color="primary">
                        Transfer
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showConfirmPIN}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.handleCloseConfirm}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{"PIN Transaksi"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <TextField
                                id="inputPINTransaksi"
                                label="PIN Transaksi"
                                type="password"
                                autoComplete="none"
                                name="password"
                                margin="normal"
                                variant="outlined"
                                inputRef={ref=>this.refPIN = ref}
                                value={this.state.pinTransaksi}
                                inputProps={{ maxLength: 6 }}
                                onChange={(e)=>this.setState({pinTransaksi:e.target.value})}
                                />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{
                            this.setState({showConfirmPIN:false});
                        }} color="primary">
                            Batal
                        </Button>
                    <Button onClick={this.onSubmitPin} color="primary">
                        Cek Rekening
                    </Button>
                    </DialogActions>
                </Dialog>
                <Card style={{marginTop : 20}}>
                    <CardContent>
                    <Grid
                        item xs={12}
                        container
                        direction="column"
                        >
                            <TextField
                                id={"norek"}
                                label={"No Rekening"}
                                type={"number"}
                                name={"norek"}
                                autoComplete="none"
                                placeholder={""}
                                margin="normal"
                                variant="outlined"
                                value={this.state.noRek}
                                onChange={(e)=>{
                                    this.setState({ noRek:e.target.value });
                                }}
                                inputRef={ref=>this.noRekRef=ref}
                            />

                            <TextField
                                id={"nominal"}
                                label={"Nominal"}
                                type={"number"}
                                name={"nominal"}
                                autoComplete="none"
                                placeholder={""}
                                margin="normal"
                                variant="outlined"
                                value={this.state.nominal}
                                onChange={(e)=>{
                                    this.setState({ nominal:e.target.value });
                                }}
                                inputRef={ref=>this.nominalRef=ref}
                            />

                            <FormControl variant="outlined" margin='normal'>
                                <InputLabel id="pilih-nominal">Pilih Bank</InputLabel>
                                <Select
                                    labelId="pilih-nominal"
                                    id="pilih-nom"
                                    value={this.state.bankCode}
                                    onChange={(e)=>this.setState({bankCode: e.target.value, bankName:e.target.value.split("-")[2]})}
                                    inputRef={ref=>this.bankRef=ref}
                                    label="Pilih Bank">
                                    <MenuItem value="">
                                        <em>--Pilih--</em>
                                    </MenuItem>
                                    {this.state.banks.map(data => (
                                        <MenuItem  key={data.id} value={data.bank_bic+"-"+data.bank_code_inq+'-'+data.bank_name}>{data.bank_name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                id={"berita"}
                                label={"Berita"}
                                type={"text"}
                                name={"berita"}
                                autoComplete="none"
                                placeholder={""}
                                margin="normal"
                                variant="outlined"
                                value={this.state.berita}
                                onChange={(e)=>{
                                    this.setState({ berita:e.target.value });
                                }}
                            />
                        </Grid>
                    </CardContent>
                </Card> 
                {this.state.showLoading?<LinearProgress style={{marginTop:10}} color="secondary"  variant="query" />:null}
                <List
                    aria-labelledby="header-list"
                    subheader={
                    <ListSubheader id="header-list">
                        Pilih Penyedia Layanan
                    </ListSubheader>
                    }>
                    {this.state.listProduk.map(data => (
                        <>
                        <ListItem component={'li'} button onClick={()=>{
                            this.handleClickOpenConfirm(data);
                        }} >
                            <ListItemText primary={data.nama_produk} secondary={<>{data.keterangan_produk} <br/> Admin {Utils.formatRupiah(data.h_jual,'.')} Fee {Utils.formatRupiah(data.fee_member,'.')}</>} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="show">
                                    <ArrowRightIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Divider variant="fullWidth" component={'li'}/>
                        </>
                    ))}
                </List>
            </>
          );
                    
        return (
            <>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{paddingTop:56}} maxWidth='sm'>
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                    {polygon}
                    </Grow>
                </ContainerLayout>
            </>
        );
    }
}


export default ProdukBank;
