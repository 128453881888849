import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Gvar from '../utils/Gvar';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ContainerLayout from '@material-ui/core/Container';
import Utils from '../utils/Utils';
import ReactHtml from 'raw-html-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    }));
    
export function TopBar(props) {
    var context = props.context
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
        <AppBar position="fixed">
            <Toolbar variant="dense">
            <IconButton edge="start" onClick={()=>{
                context.props.history.goBack()
            }} className={classes.menuButton} color="inherit" aria-label="menu">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
                {context.state.title}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            </Toolbar>
        </AppBar>
        </div>
    );
}

class BannerDetail extends Component {

    constructor(props){
        super(props);
        this.state = ({
            bannerDetail : {
                url_gambar : '',
                judul : '',
                deskripsi : '',
                tgl_dibuat : ''
            },
            title : 'Detail Banner'
        });
    }

    componentDidMount(){
        // this.props.appContext.setState({showLoading:false})
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            this.getBennerDetail(id);
        } catch (error) {
            
        }
    }

    getBennerDetail(id){
        Gvar.appContext.setState({ showSnackBar: false, textSnackBar :""});
        // var currentURL = window.location.href;
        // var jenis = currentURL.split("jenis=")[1];
        var bodyJson = { 
            id: id,
            id_member:Utils.getDataMember().id,
            token: Utils.getMyToken()
        }
        fetch(Gvar.server+'index.php/iklan/detail', {
            method: 'post',
            body: JSON.stringify(bodyJson),
        }).then(res=>res.json()
        ).then(res => {
            // console.log(res)
            this.setState({bannerDetail:res.data}); 
        }).catch(err => {
            Gvar.appContext.setState({ showSnackBar: true, textSnackBar :"Koneksi Gagal"});
            Gvar.appContext.setState({showLoading:false})
        })
    }
    
    render() {
        return (
            <div>
                <TopBar context={this}></TopBar>
                <ContainerLayout style={{paddingTop:74}} maxWidth="sm">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                        <div>
                            <Card>
                                <CardContent>
                                    <div style={{display:'flex',justifyContent:'center'}}>
                                        <img src={this.state.bannerDetail.url_gambar} alt={'No Image'} style={{width:'100%'}}></img>
                                    </div>
                                    <div>
                                        <strong>{this.state.bannerDetail.judul}</strong> <br></br>
                                        <span style={{fontSize:12}}>{this.state.bannerDetail.tgl_dibuat}</span><br></br>
                                        <ReactHtml html={this.state.bannerDetail.deskripsi}></ReactHtml>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Grow>
                </ContainerLayout>
            </div>
        );
    }
}

export default (BannerDetail);
