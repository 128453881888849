import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/style.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { initializeFirebase, askForPermissioToReceiveNotifications } from './fcmPush';
import { SessionProvider } from './utils/SessionContext';

ReactDOM.render(<SessionProvider><App /></SessionProvider>, document.getElementById('root'));

initializeFirebase();

askForPermissioToReceiveNotifications();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
