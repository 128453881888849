import React, { Component } from 'react';
import Gvar from '../utils/Gvar';

import Container from '@material-ui/core/Container';
import Utils,{loadProfile} from '../utils/Utils';
import Logger from '../utils/Logger';
import { Box } from '@material-ui/core';
import BottomTab from '../components/navigation/BottomTab';
import Home from '../pages/tab/home/Home';
import Histori from '../pages/tab/history/Histori';
import Cart from '../pages/tab/cart/Cart';
import Pengaturan from '../pages/tab/setting/Pengaturan';
import Pesan from '../pages/tab/pesan/Pesan';
import Header from '../components/navigation/Header';
import SessionContext from '../utils/SessionContext';

class MemberLayout extends Component {

    static contextType = SessionContext;

    state = {
        showDrawer : false,
        menuName : 'Dashboard',
        showLoading: true,
        showSnackBar: false, 
        textSnackBar :"",
        valueTab:"",
        isLoggedIn:false,
        tabActive:"home",
        badge:null,
        badgeCart:null,
        bindAcc:false,
        drafTrx:[],
        showDoneBtn:false
    }

    constructor(props){
        super(props);
        // Gvar.homeContext = this;
        // Gvar.data_member = this.data_member;
    }

    handleClose = () => {
        this.setState({ showSnackBar: false });
    };

    componentDidMount(){

        const queryParams = new URLSearchParams(this.props.location.search);
        const tabActive = queryParams.get('tab') || "beranda";
        // console.log(tabActive)
        this.setState({tabActive:tabActive})

        try{
            Gvar.dataMember = Utils.getDataMember();
            
            // Logger.d(dataMember);
            this.data_member = Utils.getDataMember();
        }catch(err){
            this.props.history.push('/error')
            return;
        }
        this.setState({valueTab:'belanja', showLoading:false, bindAcc:localStorage.getItem('bindingAcc')});
        this.pullMessage()
        loadProfile();
        this.getDrafTrx();

        if(tabActive == "keranjang"){
            this.setState({showDoneBtn:true})
        }else{
            this.setState({showDoneBtn:false})
        }
    }

    componentDidUpdate(prevProps) {
        // Update state jika URL berubah
        if (this.props.location.search !== prevProps.location.search) {
            const queryParams = new URLSearchParams(this.props.location.search);
            const tabActive = queryParams.get('tab') || 'beranda';
            this.setState({ tabActive: tabActive });
            if(tabActive === "keranjang"){
                this.setState({showDoneBtn:true})
            }else{
                this.setState({showDoneBtn:false})
            }
        }
    }

    getDrafTrx = (callback=()=>{}) => {
        var bodyJson = {
            start_date : Utils.formatDate(new Date()),
            end_date : Utils.formatDate(new Date()),
            no_tujuan : '',
            id_member : this.data_member.id,
            token : Utils.getMyToken(),
            status : "DRAF",
            reset_draf : localStorage.getItem("reset_draf")
        }
        
        fetch(Gvar.server+'index.php/histori/transaksi', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        })
        .then(res=>res.json())
        .then(res => {
            if(res.status_code == "200"){
                this.setState({badgeCart:res.data.length});
                callback(res.data)
            }else{
                callback([])
            }
        }).catch(err => {
            callback([])
        })
  }

  pullMessage(){

    try{
        this.data_pesan = JSON.parse(localStorage.getItem('data_pesan')==null?"[]":localStorage.getItem('data_pesan'));
            
        var bodyJson = {id_member:this.data_member.id,'token' : Utils.getMyToken()}

        const { logout } = this.context;
        
        fetch(Gvar.server+'index.php/member/tarik-pesan', {
            method: 'post',
            body: JSON.stringify(bodyJson)
        })
        .then(res=>res.json())
        .then(res =>{
            if(res.status_code == '401'){
                logout()
            }
            Logger.d('Pull','Pesan Baru');
            var pesan = res.data.concat(this.data_pesan);
            if(res.data.length > 0){
                setTimeout(() => {
                    Gvar.badgeContent = res.data.length;
                }, 2000);
            }

            this.setState({badge:Gvar.badgeContent})

            // Logger.d("pesan",pesan);
            localStorage.setItem('data_pesan',JSON.stringify(pesan));
            //Logger.d(res)
            //this.setState({listProduk:res})
        }).catch(err => {
            Logger.d("Pending failed");
        })
        }catch(err){
            Logger.d(err.toString());
        }
    }

    componentWillMount(){
        this.setState({showLoading:true});
    }

    setBadgeCart = (total=0) => {
        this.setState({badgeCart:total});
    }

    handleOnChangeBottomTab = (newValue) => {
        this.props.history.push("/?tab="+newValue);
        this.setState({ tabActive: newValue });
        if(newValue === 'inbox'){
            setTimeout(() => {
                this.setState({badge:null})
            }, 1000);
        }
    }

    renderContent = () => {

        switch (this.state.tabActive) {
          case 'beranda':
            return <Home history={this.props.history}/>;
          case 'histori':
            return <Histori history={this.props.history}/>;
          case 'inbox':
            return <Pesan history={this.props.history}/>;
        case 'keranjang':
            return <Cart history={this.props.history} setBadgeCart={this.setBadgeCart}/>;
          case 'pengaturan':
            return <Pengaturan history={this.props.history}/>;
          default:
            return <Home history={this.props.history}/>;
        }
    };

    render() {
        return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <Header title={this.state.tabActive.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())} showBackButton={this.props.showBackButton} onBack={this.props.onBack} getDrafTrx={this.getDrafTrx} showDoneBtn={this.state.showDoneBtn}/>
            <div style={{ flex: 1, overflow: 'auto', paddingTop: 48, paddingBottom: 58 }}>
                <Container maxWidth="sm">
                    <Box style={{padding: 16}}>
                        {this.renderContent()}
                    </Box>
                </Container>
            </div>
            <BottomTab history={this.props.history} onChange={this.handleOnChangeBottomTab} tabActive={this.state.tabActive} badge={this.state.badge} badgeCart={this.state.badgeCart} bindAcc={this.state.bindAcc}/>
        </div>
        );
    }
}

export default MemberLayout;
