import { Badge, BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { Inbox, ShoppingCart, History, Home, Settings } from "@material-ui/icons";
import React from "react";

export default class BottomTab extends React.Component{

    constructor(props){
        super(props);
    }

    handleChange = (event, newValue) => {
        this.props.onChange(newValue);
    };

    render (){
        return(
            <>
                <BottomNavigation
                    // style={{margin: '0 auto', width:'100%'}}
                    value={this.props.tabActive}
                    onChange={this.handleChange}
                    showLabels
                    className="bottom-tab"
                >
                    <BottomNavigationAction label="Beranda" value="beranda" icon={<Home/>} />
                    <BottomNavigationAction label="Histori" value="histori" icon={<History />} />
                    {this.props.bindAcc?(
                        <BottomNavigationAction label="Keranjang" value={"keranjang"} icon={
                            <Badge badgeContent={this.props.badgeCart} color="secondary">
                                <ShoppingCart />
                            </Badge>
                        } />
                    ):(
                        <BottomNavigationAction label="Inbox" value="inbox" icon={
                            <Badge badgeContent={this.props.badge} color="secondary">
                                <Inbox />
                            </Badge>
                        } />
                    )}
                    <BottomNavigationAction label="Pengaturan" value="pengaturan" icon={<Settings />} />
                </BottomNavigation>
            </>
        )
    }
}