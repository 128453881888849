import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('data_member'));
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          render ? (
            render(props)
          ) : (
            <Component {...props} />
          )
        ) : <Redirect to="/login" />
      }
    />
  );
};

export default PrivateRoute;