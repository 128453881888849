import React, { Component } from 'react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom'
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import OutlinedInput from '@material-ui/core/OutlinedInput';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import Gvar from '../../../utils/Gvar';
// import { Divider } from '@material-ui/core';
// import DateFnsUtils from '@date-io/date-fns';
// import {MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
// import { BrowserRouter as Router, Route, Link} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class Pengaturan extends Component {
    
    state = {
        showConfirmDialog:false,
        bindingAcc: false
    }
    constructor(props){
        super(props);
    }

    componentDidMount(){
        let bindingAcc = localStorage.getItem('bindingAcc');
        if(bindingAcc){
            this.setState({bindingAcc:true})
        }
    }

    handleClose = () => {
        this.setState({ showConfirmDialog: false });
    };

    render() {
        const polygon = (
            <>
            <Card style={{marginTop : 20}}>
                <CardContent>
                    <List>
                    {!this.state.bindingAcc?(
                        <ListItem key={1} 
                            onClick={()=>{
                                this.props.history.push('/gantipassword', {activeTab: this.props.activeTab});
                            }}
                            button
                            // selected={this.state.selectedIndex === 2}
                            // onClick={event => this.handleListItemClick(event, 2)}
                            >
                            <ListItemText primary={'Ganti Password'} secondary={'Ganti password saat login'}/>
                        </ListItem>
                    ):null}
                        <ListItem key={2} button
                            onClick={()=>{
                                this.props.history.push('/gantipin')
                            }}
                            // selected={this.state.selectedIndex === 2}
                            // onClick={event => this.handleListItemClick(event, 2)}
                            >
                            <ListItemText primary={'Ganti PIN Transaksi'} secondary={'Ganti PIN saat melakukan transaksi pulsa dan lainnya'}/>
                        </ListItem>
                        <ListItem key={3} button
                            onClick={()=>{
                                this.props.history.push('/updateharga', {activeTab: this.props.activeTab})
                            }}
                            // selected={this.state.selectedIndex === 2}
                            // onClick={event => this.handleListItemClick(event, 2)}
                            >
                            <ListItemText primary={'Harga Jual'} secondary={'Mengatur harga jual'}/>
                        </ListItem>
                    </List>
                </CardContent>
            </Card> 
            {!this.state.bindingAcc?(
                <Card style={{marginTop : 20}}>
                    <CardContent>
                        <Button onClick={()=>{
                            this.setState({showConfirmDialog:true});
                        }} color={'primary'}>Logout</Button>
                    </CardContent>
                </Card>
            ):null}
            <Dialog
                open={this.state.showConfirmDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Konfirmasi"}</DialogTitle>
                <DialogContent>
                    Keluar dari akun.
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Batal
                    </Button>
                    <Button onClick={()=>{
                        setTimeout(() => {
                            localStorage.setItem('is_logged_in',false);
                            localStorage.setItem('data_member', null);
                            this.props.history.replace("/login")
                        }, 1000);

                        this.setState({showConfirmDialog:false})
                    }} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            </>
          );
                    
        return (
            <>
                <Grow in={true} style={{ transformOrigin: '0 0 0' }}>
                {polygon}
                </Grow>
            </>
        );
    }
}

export default Pengaturan;
