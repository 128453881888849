import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Done } from '@material-ui/icons';

function callbackFlutter(data = [], callback = () => {}){
  try {
    window.flutterCallback.postMessage(JSON.stringify(data));
  } catch (error) {
    alert(error.toString())
  }
  callback()
}

const Header = ({ title, showBackButton, onBack, showDoneBtn, getDrafTrx=()=>{} }) => {

  const done = () => {
    getDrafTrx((dataDraf)=>{
      callbackFlutter(dataDraf, ()=>{
        console.log(dataDraf)
      });
    })
  }

  return (
    <AppBar position="fixed">
      <Toolbar variant='dense'>
        {showBackButton && (
          <IconButton edge="start" color="inherit" onClick={onBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography variant="h6">{title}</Typography>
        {showDoneBtn && (<Button style={{marginLeft:'auto'}} startIcon={<Done />} color="inherit" onClick={done}>Selesai</Button>)}
      </Toolbar>
    </AppBar>
  );
};

export default Header;